<template>
  <!-- 个人中心 -->
  <div class="person">
    <img src="@/assets/images/user/peopleCenter/xinxi.png" alt class="xinxi" />
    <div class="main">
      <!-- 修改昵称 -->
      <div class="nickname">
        <img
          src="@/assets/images/user/peopleCenter/xiugai.png"
          alt
          class="name"
        />
        <div class="xiugai">
          <input type="text" v-model="username" />
          <div class="bd" @click="changeName()">修改昵称</div>
          <!-- <img
            src="@/assets/images/user/peopleCenter/tijiao.png"
            alt
            class="tijiao"
            @click="changeName()"
          />-->
        </div>
      </div>
      <!-- 修改密码 -->
      <div class="nickname">
        <img
          src="@/assets/images/user/peopleCenter/password.png"
          alt
          class="name"
        />
        <div class="xiugai">
          <input type="password" v-model="password" />
          <div class="bd" @click="changePwd()">修改密码</div>
        </div>
      </div>
      <!-- 交易链接 -->
      <div class="nickname">
        <img
          src="@/assets/images/user/peopleCenter/link.png"
          alt
          class="name"
        />
        <div class="xiugai">
          <input type="text" v-model="givemoney" placeholder="暂无" />
          <div class="bd" @click="getmoery()">绑定链接</div>
        </div>
      </div>
      <!-- 邀请码 -->
      <div class="nickname">
        <div class="yaoqing">邀请码：</div>
        <div class="xiugai">
          <input type="text" v-model="placecode" placeholder="暂无" disabled />
          <div class="bd" @click="copy()">复制</div>
        </div>
      </div>
      <!-- 上级邀请码 -->
      <div class="nickname">
        <div class="yaoqing">上级邀请码：</div>
        <div class="xiugai">
          <input type="text" v-model="superiorId" placeholder="暂无" />
          <div class="bd" @click="getuser(superiorId)">绑定邀请码</div>
        </div>
      </div>
      <!-- 实名认证 -->
      <div class="nickname">
        <div class="yaoqing">实名认证：</div>
        <div class="xiugai">
          <input type="password" value="password" disabled />
          <div class="bd" @click="userss">实名认证</div>
        </div>
      </div>
    </div>
    <!-- 退出登录 -->
    <div class="logout" @click="loginOut">退出登录</div>
    <!-- 实名认证弹窗 -->
    <div class="pop" v-if="isshow">
      <div class="popinp">
        <div class="poptitle">实名认证</div>
        <div>姓&emsp;&emsp;名：<input v-model="usernames" type="text" /></div>
        <div>身份证号：<input v-model="idCard" type="text" /></div>
        <div class="popbnt d-flex">
          <button @click="getusername(usernames, idCard)">确定</button>
          <button @click="isshow = false">取消</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUserInfo,
  updataImgAPI,
  giveMoneyAPI,
  updatauserAPI,
  giveUserAPI,
  getusername,
  queryusername,
  changePass,
} from "../../../api/index";
import { removeToken } from "../../../api/cookie";
export default {
  name: "testHeadImg",
  data() {
    return {
      usernames: "",
      isshow: false,
      idCard: "",
      money: 0,
      phone: "",
      username: "",
      password: "",
      portrait: "",
      storeBean: "",
      placecode: "",
      givemoney: "",
      superiorId: "",
      userid: "",
      IsCocropper: false,
      headImg: "", //剪切图片上传
      crap: false,
      previews: {},
      option: {
        img: "",
        outputSize: 1, //剪切后的图片质量（0.1-1）
        full: false, //输出原图比例截图 props名full
        outputType: "png",
        canMove: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        fixedBox: true,
        autoCropWidth: 200,
        autoCropHeight: 150,
      },
      fileName: "", //本机文件地址
      imgFile: {},
      uploadImgRelaPath: "", //上传后的图片的地址（不带服务器域名）
    };
  },
  mounted() {
    getUserInfo().then((res) => {
      console.log(res);
      this.money = res.data.user.bean;
      this.phone = res.data.user.phone;
      this.username = res.data.user.name;
      this.portrait = res.data.user.portrait;
      this.storeBean = res.data.user.storeBean;
      this.placecode = res.data.user.invitationCode;
      this.givemoney = res.data.user.transactionLink;
      // 上级邀请码
      this.superiorId = res.data.parentInvitationCode;
      this.userid = res.data.user.id;
    });
  },
  methods: {
    userss() {
      queryusername().then((res) => {
        console.log(res);
        if (res.data.code == 500) {
          this.isshow = true;
        } else {
          this.$message.error("您已实名认证");
        }
      });
    },
    getusername(usernames, idCard) {
      getusername(usernames, idCard).then((res) => {
        // console.log(res);
        const url = res.data.data;
        location.href = url;
      });
      this.isshow = false;
    },
    changePwd() {
      // let phone=
      let data = {
        newPassword: this.password,
        phone: this.phone,
      };
      changePass(data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success("修改成功，请登录！");
          this.loginOut();
        } else {
          this.$message.error("错误，请重试！");
        }
      });
    },
    loginOut() {
      removeToken();
      this.$router.push({ name: "Home" });
      this.$router.go(0);
    },
    copy() {
      this.$common.CopyText({
        content: this.placecode,
      });
    },
    changeName() {
      let data = {
        id: this.userid,
        name: this.username,
      };
      updatauserAPI(data).then((res) => {
        this.$message(res.data.msg);
      });
    },
    getmoery() {
      let formatObj = this.givemoney.replace(/&/g, "%26");
      giveMoneyAPI(formatObj).then((res) => {
        if (res.data.code == 500) {
          this.$message.error("绑定失败");
        } else if (res.data.code == 200) {
          this.$message.success("绑定成功");
        } else {
          this.$message.warning("请联系客服");
        }
      });
    },
    getuser(superiorId) {
      giveUserAPI(superiorId).then((res) => {
        if (res.data.code == 500) {
          this.$message.error(res.data.msg);
        } else if (res.data.code == 200) {
          this.$message.success("绑定成功");
        }
      });
    },
    //上传图片（点击上传按钮）
    finish() {
      updataImgAPI(this.previews.url).then((res) => {
        // console.log(res);
        // console.log(this.previews.url);
        if (res.data.code === 200) {
          // this.getUserInfo();
          this.$toast({
            message: "修改头像成功",
          });
        } else {
          this.$toast({
            message: "修改头像失败",
          });
        }
      });
      this.IsCocropper = false;
    }, // 实时预览函数
    realTime(data) {
      // console.log(data);
      this.previews = data;
    },
    //选择本地图片
    uploadImg(event) {
      let imgFile = {};
      let _this = this; //上传图片
      let file = event.target.files[0];
      imgFile = file;
      _this.imgFile = imgFile;
      _this.fileName = file.name;
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(event.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        _this.option.img = data;
        event.target.value = ""; //避免每次选择同样的文件时，input @change 方法不执行问题
      }; // 转化为base64 // reader.readAsDataURL(file) // 转化为blob
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>

<style scoped lang="scss">
.pop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1999;
  background-color: rgba(0, 0, 3, 0.803);
}
.person {
  width: 100%;
  height: 100%;
  //background-color: green;

  .xinxi {
    display: block;
    width: 192px;
    height: 37px;
  }

  .main {
    width: 450px;
    height: 210px;
    overflow: scroll;

    .nickname {
      width: 450px;
      height: 54px;
      margin-top: 10px;

      .name {
        display: block;
        margin-bottom: 6px;
      }

      .xiugai {
        display: flex;

        input[type="text"] {
          width: 330px;
          height: 30px;
          padding-left: 20px;
          border-radius: 4px;
          background-color: #111f3a52;
          font-family: "楷体";
          outline: 0;
          font-size: 12px;
          color: #fff;
          text-decoration: none;
        }

        input[type="password"] {
          width: 330px;
          height: 30px;
          padding-left: 20px;
          border-radius: 4px;
          background-color: #111f3a52;
          font-family: "楷体";
          outline: 0;
          font-size: 12px;
          color: #fff;
          text-decoration: none;
        }

        .tijiao {
          display: block;
          margin-left: 10px;
          width: 90px;
          height: 40px;
        }

        .bd {
          flex: none;
          margin-left: 20px;
          margin-top: 6px;
          font-size: 14px;
          color: #fff;
        }
      }

      .yaoqing {
        font-size: 14px;
        color: #fff;
      }
    }
  }
  .logout {
    font-size: 18px;
    color: #fff;
    margin-top: 12px;
    background: url("~@/assets/images/logo/loginbtn.png");
    background-size: 100% 100%;
    width: 100px;
    height: 45px;
    text-align: center;
    line-height: 45px;
  }
  .popinp {
    width: 500px;
    height: 300px;
    display: flex;
    margin: auto;
    position: fixed;
    z-index: 2001;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("~@/assets/images/logo/border.png") no-repeat;
    background-size: 100% 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    color: rgb(255, 255, 255);
    .poptitle {
      text-align: center;
      font-size: 25px;
      color: aqua;
      margin-top: -60px;
    }
    div {
      width: 450px;
      margin-top: 20px;
      input {
        flex: none;
        width: 330px;
        height: 30px;
        padding-left: 10px;
        border-radius: 4px;
        background-color: #111f3a52;
        font-family: "楷体";
        outline: 0;
        font-size: 12px;
        color: #fff;
        text-decoration: none;
      }
    }
    .popbnt {
      justify-content: space-around;
      button {
        width: 100px;
        height: 35px;
        background-color: #00aae8;
        outline: none;
        border: none;
        border-radius: 5px;
        color: white;
      }
    }
  }
}
@media (max-width: 550px) {
  .person {
    width: 100%;
    height: 100%;
    //background-color: green;

    .xinxi {
      display: none;
    }

    .main {
      width: 92%;
      height: 350px;
      overflow: scroll;
      margin: auto;
      padding-left: 20px;

      .nickname {
        width: 100%;
        height: 60px;
        margin-top: 10px;

        .name {
          display: block;
          margin-bottom: 6px;
        }

        .xiugai {
          display: flex;

          input[type="text"] {
            width: 60%;
            height: 30px;
            padding-left: 20px;
            border-radius: 4px;
            background-color: #111f3a52;
            font-family: "楷体";
            outline: 0;
            font-size: 12px;
            color: #fff;
            text-decoration: none;
          }

          input[type="password"] {
            width: 60%;
            height: 30px;
            padding-left: 20px;
            border-radius: 4px;
            background-color: #111f3a52;
            font-family: "楷体";
            outline: 0;
            font-size: 12px;
            color: #fff;
            text-decoration: none;
          }

          .tijiao {
            display: block;
            margin-left: 10px;
            width: 90px;
            height: 40px;
          }

          .bd {
            width: 80px;
            height: 20px;
            text-align: center;
            flex: none;
            margin-left: 20px;
            margin-top: 6px;
            font-size: 14px;
            color: #fff;
            padding: 10px;
            background: url("~@/assets/images/logo/loginbtn.png") no-repeat;
            background-size: 100% 100%;
          }
        }

        .yaoqing {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .logout {
      font-size: 18px;
      color: #fff;
      margin-top: 12px;
      background: url("~@/assets/images/logo/loginbtn.png");
      background-size: 100% 100%;
      width: 100px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      margin: auto;
    }
  }
  .popinp {
    width: 360px !important;
    height: 300px;
    display: flex;
    margin: auto;
    position: fixed;
    z-index: 2001;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("~@/assets/images/logo/border.png") no-repeat;
    background-size: 100% 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    color: rgb(255, 255, 255);
    .poptitle {
      text-align: center;
      font-size: 25px;
      color: aqua;
      margin-top: -60px;
    }
    div {
      width: 335px !important;
      margin-top: 20px;
      input {
        flex: none;
        width: 240px !important;
        height: 30px;
        padding-left: 10px;
        border-radius: 4px;
        background-color: #111f3a52;
        font-family: "楷体";
        outline: 0;
        font-size: 12px;
        color: #fff;
        text-decoration: none;
      }
    }
    .popbnt {
      justify-content: space-around;
      button {
        width: 100px;
        height: 35px;
        background-color: #00aae8;
        outline: none;
        border: none;
        border-radius: 5px;
        color: white;
      }
    }
  }
}
</style>
